import FadeIn from '../../common/utils/fadeIn';
import SlideUpP from '../../common/utils/slideUpP';
import { Homepage } from '@/typings/types';

type PresentationWrapperProps = {
  presentation: Homepage['presentation'];
};

const PresentationWrapper = ({ presentation }: PresentationWrapperProps) => {
  return (
    <section className={'relative w-11/12 pt-20 lg:ml-28 lg:w-8/12 lg:pl-56 xl:w-5/12'}>
      <SlideUpP
        delay
        delayDuration={350}
        yOffset={-10}
        className={'pb-6 font-stigsaMedium text-3xl uppercase text-chocolate'}
      >
        {presentation.content}
      </SlideUpP>

      <FadeIn delay className={'mb-10'}>
        <p className={'font-libertineRegular text-base uppercase'}>{presentation.name}</p>
      </FadeIn>
    </section>
  );
};

export default PresentationWrapper;
